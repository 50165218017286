import React, { ReactElement } from 'react'
import { PageProps } from 'gatsby'

import { getPage } from '@/themes'

/**
 * NotFound page
 */
export default function Article(props: PageProps): ReactElement {
  const NotFoundPage = getPage(`NotFound`)
  return <NotFoundPage {...props} />
}
